import ProductItems from "./components/ProductItems";
import { useContext } from "react";

import PageTemplate from "src/pages/PageTemplate";
import PageTitle from "src/custom_components/PageTitle";

import MyCard from "./MyCard";
import ProductsPageTopSection from "./components/ProductsPageTopSection";
import { Helmet } from "react-helmet-async";
import { AccessContext } from "src/context/AccessContext";
import ResourceModelType from "src/models/ResourceModelType";
import ResourceModel from "src/models/ResourceModel";
import { Typography } from "@mui/material";

const filterForProductsPage = (resources: ResourceModel[] | null) => {
  if (resources === null) return null;
  return resources.filter((resource) => resource.availableOnProductsPage);
};

const ProductsPage = () => {
  const { getAllResourceModelsByResourceType } = useContext(AccessContext);

  const courseResourceModels = filterForProductsPage(getAllResourceModelsByResourceType(ResourceModelType.course));
  const questionBankResourceModels = filterForProductsPage(
    getAllResourceModelsByResourceType(ResourceModelType.questionBank)
  );
  const mockPaperResourceModels = filterForProductsPage(
    getAllResourceModelsByResourceType(ResourceModelType.mockPaper)
  );
  const workshopResourceModels = filterForProductsPage(getAllResourceModelsByResourceType(ResourceModelType.workshop));

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: "/products/" }]}>
        <title>Products | Pre-Reg Shortcuts</title>
        <meta
          name="description"
          content="Discover the all in one comprehensive Foundation Pharmacist Training programme with an approved GPhC training plan, browse our specialised programme and exam revision courses."
        />
        <meta
          name="keywords"
          content="Foundation Pharmacist Training Programme, GPhC Approved Training Plan, Pharmacy Exam Revision Courses, Pre-registration Pharmacist Training, Pharmacy Careers, Foundation Pharmacist Courses, BNF exam revision, Calculations questions practice, GPhC exam revision"
        />
      </Helmet>
      <PageTemplate>
        <ProductsPageTopSection />
        <MyCard customStyle={{ marginTop: "2rem" }}>
          <PageTitle title="Exam Revision Courses & Workshops" />
          <p>
            Our Exam Revision Course & Workshops are separate components for anyone who is interested in joining Pre-Reg
            Shortcuts but not on the complete Foundation Pharmacist Training Programme.
          </p>
          <ProductItems
            title="Exam Revision Courses"
            link="/courses/"
            content={
              <>
                <p style={{ marginTop: 0 }}>
                  {" "}
                  The same exam revision courses in the Foundation Pharmacist Training Programme only you can choose the
                  courses of your choice, these courses are fully recorded with workbooks so you'll have immediate
                  access for 365 days from the day you enrol.
                </p>
                <p style={{ marginBottom: 0 }}>
                  Please note: The separate exam revision courses are not eligible for the Live Study Sessions or
                  Telegram group, if you'd like the live online study sessions you must enrol on the Foundation
                  Pharmacist Training Programme
                </p>
              </>
            }
            resourceModels={courseResourceModels}
          />
          <ProductItems
            title="Question Bank"
            link="/question-bank/"
            content="Access over 1000 GPhC style questions to practice for your revision, we have single best answer, extended matching and Calculations questions. Build your confidence and practice questions now."
            resourceModels={questionBankResourceModels}
          />
          <ProductItems
            title="Mock Paper"
            link="/mock-paper/"
            content="Experience the full GPhC exam day simulation with our comprehensive online mock paper, which covers Part 1 Calculations and Part 2 Clinical papers. Boost your confidence, refine your exam strategy, and excel on exam day with our popular mock paper."
            resourceModels={mockPaperResourceModels}
          />
          <ProductItems
            title="Workshops"
            link="/career-workshops"
            content="Considering your Pharmacy Career options? Join our live full day workshops, Hospital & Prescribing but more to come! Learn the necessary steps to transition to another sector the easy way with the guidance of our expert experienced tutors, who will guide you through your new career role, There may be Pharmacist job opportunities available too!"
            resourceModels={workshopResourceModels}
          />
        </MyCard>

        <Typography variant="body2" sx={{ mt: 4 }}>
          Pre Reg Shortcuts Ltd is authorised and regulated by the Financial Conduct Authority (FRN: 1010725). We act as
          a credit broker not a lender. We will only introduce you to Klarna Financial Services UK Ltd (FRN: 1010725).
          Klarna may offer you their unregulated 'pay in three instalments' or 'pay in 30 days' finance products (both
          are interest-free, 0% APR). They may also offer you their FCA regulated extended term finance plan (up to 12
          months), this option will also be interest-free (0% APR). The finance options available will be clearly
          displayed at checkout under 'Financing'. If you would like to know how we handle complaints, please ask for a
          copy of our complaints handling process. You can also find information about referring a complaint to the
          Financial Ombudsman Service (FOS) at financial-ombudsman.org.uk. This service is not available for the
          unregulated 'pay in three instalments' or 'pay in 30 days' Klarna finance products.
        </Typography>
      </PageTemplate>
    </>
  );
};

export default ProductsPage;
