import { useContext } from "react";
import { Stack } from "@mui/material";

import RoutedButton from "src/newComponents/buttons/RoutedButton";
import img from "src/assets/foundation_training_programme.png";
import useResponsive from "src/hooks/useResponsive";
import useIsLightMode from "src/hooks/useIsLightMode";
import AddResourceToBasket from "src/newComponents/AddResourceToBasket";
import ResourceCost from "src/newComponents/ResourceCost";

import { AccessContext } from "src/context/AccessContext";
import { resourceCanBePurchased } from "src/repository/resourceRepository";

import MyCard from "../MyCard";
import KlarnaPayment from "./KlarnaPayment";

export default function ProductsPageTopSection() {
  const { getResourceModelByResourceType } = useContext(AccessContext);
  const resourceModel = getResourceModelByResourceType("foundationTrainingProgramme");

  const isSmDown = useResponsive("down", "sm");
  const isLightMode = useIsLightMode();
  return (
    <MyCard
      customStyle={{
        backgroundColor: isLightMode ? "#efe3c8" : "#cba249",
        marginTop: "0",
      }}
    >
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        spacing={4}
      >
        <img
          src={img}
          alt="Foundation Training Programme"
          loading="eager"
          title="Foundation Training Programme"
          style={{
            width: isSmDown ? "100%" : "370px",
            height: isSmDown ? "auto" : "265px",
          }}
        />

        <Stack direction="column">
          <h2
            style={{
              marginTop: isSmDown ? 0 : "1rem",
              marginBottom: "8px",
            }}
          >
            FOUNDATION PHARMACIST TRAINING PROGRAMME
          </h2>
          <h3
            style={{
              marginTop: 0,
            }}
          >
            ALL IN ONE PROGRAMME
          </h3>
          <p
            style={{
              marginTop: 0,
              marginBottom: "6px",
            }}
          >
            Includes a GPhC approved training plan, 50+ revision workbooks, GPhC registration assessment courses
            (Calculations, BNF, OTC and MEP), over 50 interactive live study sessions, question bank with over 1000 GPhC
            style questions, full mock paper and 1-2-1 progress report review calls.
          </p>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>
            This all in one programme includes 365 days access to everything you need to succeed in your registration
            assessment and beyond!
          </p>
          <p style={{ marginTop: 0, marginBottom: "6px" }}>
            <strong>Early bird discount:</strong> £200 discount if you enrol before 30th August 2024. Use code FTY2024
          </p>

          {resourceCanBePurchased(resourceModel) && <KlarnaPayment cost={resourceModel.cost} />}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: isSmDown ? "1rem" : "-0.5rem",
              gap: "1rem",
              flexDirection: isSmDown ? "column" : "row",
            }}
          >
            {resourceModel && (
              <Stack direction="row" alignItems="center" spacing={1.5}>
                {resourceCanBePurchased(resourceModel) && (
                  <ResourceCost
                    cost={resourceModel.cost}
                    originalCost={resourceModel.originalCost}
                    paddingTop={false}
                  />
                )}
                <AddResourceToBasket resourceModel={resourceModel} />
              </Stack>
            )}

            <RoutedButton path="/foundation-training-programme/" variant="contained" buttonColor="primary">
              Learn More
            </RoutedButton>
          </div>
        </Stack>
      </Stack>
    </MyCard>
  );
}
