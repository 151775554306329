import React from "react";

const WhyUsContent = () => {
  return (
    <div>
      <p>
        Welcome to Pre-Reg Shortcuts, your gateway to achieving excellence in your Pharmacy foundation year training!
        Beyond being an e-learning platform and online community, we're your dedicated partner on your journey to
        success. With an impressive track record and a commitment to excellence, here's why Pre-Reg Shortcuts is the
        perfect choice for your foundation year programme:
      </p>

      <ol>
        <li>
          <strong>Community of Success and Resonating Testimonials</strong>
          <p>
            Become part of our thriving community of over 5500+ aspiring Pharmacists who've excelled in their GPhC
            registration exam with Pre-Reg Shortcuts. Our excellent{" "}
            <a
              href="https://uk.trustpilot.com/review/preregshortcuts.com"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              Trustpilot reviews (rated 4.8 / 5)
            </a>{" "}
            and outstanding testimonials reflect our success.
          </p>
        </li>

        <li>
          <strong>Impressive Pass Rates</strong>
          <p>
            Our trainees achieved a 95% pass rate in the June 2023 GPhC registration assessment. We are extremely proud
            of this incredible achievement.
          </p>
        </li>

        <li>
          <strong>Excellent Customer Service and Satisfaction Guaranteed</strong>
          <p>
            At Pre-Reg Shortcuts, we value our trainees and put your success at the heart of everything we do. Our
            excellent customer service team provides unwavering support during your educational journey. Our
            user-friendly platform ensures seamless material access, letting you focus on your revision. With our
            programme, you can enrol with confidence, thanks to our satisfaction-focused money-back guarantee on the
            combo course.
          </p>
        </li>

        <li>
          <strong>Flexibility and Affordability</strong>
          <p>
            Enjoy full access to our programme from your very first instalment. We understand your financial needs and
            are committed to making your success attainable through offering a variety of course discounts, raffles and
            payment plans.
          </p>
        </li>

        <li>
          <strong>Comprehensive Learning Resources and Experience</strong>
          <ul style={{ margin: "12px 0px" }}>
            <li>
              Combo Course: Equipping you with vital clinical and calculations skills in line with the GPhC exam
              framework, forming a robust foundation for your GPhC registration assessment.
            </li>
            <li>
              MEP and OTC Course: Delve into Medicines Ethics & Practice (MEP) and Over-The-Counter (OTC) modules with
              22 recorded lessons and live sessions for exam success.
            </li>
            <li>
              1000+ GPhC Style Questions: Access over 1000 up to date GPhC style questions to enhance your exam
              preparation and boost your confidence.
            </li>
            <li>
              Full GPhC Style Mock Paper: Included in our essential plan and above, this lets you practice under exam
              conditions, pinpointing strengths and areas for growth.
            </li>
            <li>
              Comprehensive Learning: Beyond the foundation year, we cover specialised topics like gaining an insight
              into hospital Pharmacy, personal development, leadership and clinical skills training for a well-rounded
              pharmacy career.
            </li>
          </ul>
        </li>

        <li>
          <strong>Engaging Interactive Learning</strong>
          <p>
            Participate in 40+ live study sessions alongside 80+ recorded self study lessons, offering real-time support
            and personal interactivity tailored to your learning style.
          </p>
        </li>

        <li>
          <strong>Industry Acknowledgment</strong>
          <p>
            With 4 Chemist and Druggist Award nominations, our commitment to excellence is recognised nationally by
            Pharmacists and Trainees.
          </p>
        </li>

        <li>
          <strong>Expert Support and Community</strong>
          <p>
            Join our exclusive Telegram group to connect with over 2000 fellow members and experienced Pharmacists who
            are your dedicated partners throughout the journey. This collaborative hub offers insights, networking, and
            expert guidance to enhance your learning journey and navigate challenges successfully.
          </p>
        </li>

        <li>
          <strong>Giving Back to the Community</strong>
          <p>
            We sponsor 100 trainees annually, providing free enrolment onto our foundation year programme. Sponsorships
            are awarded during our webinars with 4th-year Pharmacy students and our free webinars series by attending
            live in a raffle.
          </p>
        </li>

        <li>
          <strong>Stay Informed</strong>
          <p>
            Stay updated and enrich your learning journey with Pre-Reg Shortcuts. Join our mailing list for free
            questions, videos, and updates. Plus, explore a library of over 200 FREE educational videos on our{" "}
            <a
              href="https://www.youtube.com/channel/UCj1Lx4xNGk7sHQ1O7H2XDEQ"
              className="faqPageLink"
              target="_blank"
              rel="noreferrer"
            >
              YouTube channel
            </a>
            .
          </p>
        </li>

        <li>
          <strong>Lifelong Learning and Exclusive Perks</strong>
          <p>
            At Pre-Reg Shortcuts, we're your lifelong learning partner. Beyond your foundation year, we provide
            continuous support for trainee Pharmacists and beyond, ensuring growth and success remain constant. For
            example, as a premium plan member, enjoy an exclusive 30% discount on flu vaccination training with our
            partner ECG and a FREE Leadership training program with the NPA.
          </p>
        </li>

        <li>
          <strong>Active Engagement and Sponsorship</strong>
          <p>
            We actively engage with the pharmacy community and sponsor the BPSA (British Pharmaceutical Students'
            Association). This commitment includes participation in renowned conferences like the Pharmacy Show and
            Clinical Pharmacy Congress, where we engage with the next generation of Pharmacists, share our expertise,
            and contribute to the growth and development of pharmacy students.
          </p>
        </li>

        <li>
          <strong>Certificate for Continuous Professional Development</strong>

          <p>Receive a certificate that showcases your dedication to ongoing learning and professional development.</p>
        </li>
      </ol>
    </div>
  );
};

export default WhyUsContent;
