import React from "react";

const FullTnC = () => {
  return (
    <div>
      <p>Terms and conditions for Preregshortcuts Ltd</p>
      <p>Standard Terms for the Purchase of any of our programmes</p>
      <p>
        These terms and conditions apply to services and courses provided by Preregshortcuts Ltd (company number
        11723692) of 2a Rickyard Barn Stoke Road, Blisworth Hill Farm, Northampton, Northamptonshire, England, NN7 3DB.
        You may contact us on info@preregshortcuts.com
      </p>
      <p>
        These terms and conditions apply to the sale of any product or service by Preregshortcuts Ltd. Please read these
        terms and conditions carefully before purchasing any of our products or services and print off a copy for your
        records.
      </p>
      <p>
        If there is any conflict between these terms and conditions and any Course Specific Terms and Conditions which
        might apply to a specific Online Course or Taught Course then the conflict shall be resolved by applying the
        following order of priority:
      </p>
      <p>Course Specific Terms and Conditions;</p>
      <p>These Standard Terms for the Purchase of Online and Taught Courses.</p>
      <p>
        For purchases via our website, by clicking on the “Accept” button you agree to the terms of this agreement which
        will bind you. If you do not agree to these terms and conditions you must cease to continue to purchase any
        Services from us.
      </p>
      <h4>1. Definitions</h4>
      <p>
        “Confidential Information” means information provided by one party to the other in written, graphic, recorded,
        machine readable or other form concerning the business, clients, suppliers, finances and other areas of the
        other party's business or products, including, without limitation, the Course Materials, but does not include
        information in the public domain other than through the default of the party disclosing the information,
        information required to be disclosed by any court or regulatory authority, or any information already in the
        possession or control of the disclosing party.
      </p>
      <p>
        “Course Materials” means the information provided by Preregshortcuts Ltd to accompany a course provided as part
        of the Services in hard copy or electronic form.
      </p>
      <p>“Fees” means the fees paid by you to Preregshortcuts Ltd for the products or Services.</p>
      <p>
        “Intellectual Property Rights” means copyright, rights in or relating to databases, patent rights, performers'
        rights, designs and registered designs, trademarks, rights in or relating to Confidential Information and other
        intellectual property rights (registered or unregistered) throughout the world.
      </p>
      <p>
        “Online Course” means the delivery by us of an online course pursuant to which you learn course materials
        remotely.
      </p>
      <p>
        “Services” means the provision of the Online Course and/or the Taught Course and/or the Course Materials
        together with such other services as agreed from time to time and purchased by you through the Website.
      </p>
      <p>“Taught Course” means a course taught by us in a classroom setting to which you attend in person.</p>
      <p>“Website” means www.preregshortcuts.com</p>
      <p>“you” means the individual purchasing the Services.</p>
      <h4>2. The Online programmes or services</h4>
      <p>
        2.1. A description of the online programmes or services together with the dates on which the programmes or
        services will begin are available on our Website. We will provide the programmes or services in accordance with
        the description set out on the Website.
      </p>
      <p>
        2.2. We reserve the right to vary or withdraw any of the programmes or Services described on the Website without
        notice.
      </p>
      <p>
        2.3. We expect you to confirm that the programmes or services you are purchasing will meet your needs. We do not
        make any guarantee to you that you will obtain a particular result, professional qualification or employment
        opportunity from your purchase and completion of any of the programmes or services.
      </p>
      <h4>3. Ordering Programmes or services</h4>
      <p>Purchasing Programmes via the Website</p>
      <p>
        3.1. In order to purchase any of the programmes or services on-line you must first ensure you have read our
        terms and conditions
      </p>
      <p>
        3.2. When you place an order for a programme via the Website, you are offering to purchase the Services on these
        terms and conditions. Preregshortcuts Ltd reserves the right to cancel or decline your order or any part of your
        order at any time until it has been confirmed in accordance with clause 3.4 below.
      </p>
      <p>
        3.3. Following receipt by us of your order via the Website we will contact you via email/text message confirming
        receipt of your order.
      </p>
      <p>
        3.4. A legally binding agreement between us and you shall come into existence when we have:
        <ul>
          <List
            items={[
              "accepted your offer to purchase products or services from us by sending you an email or text message confirming the purchase; and ",
              "received payment of the relevant Fees from you in accordance with clause 5 below.",
              "you have been sent login details and access to all course material",
            ]}
          />
        </ul>
      </p>
      <p>
        3.5. Where your order consists of multiple Online programmes or multiple Taught programmes , each individual
        programme will be treated by us as a separate offer to purchase.
      </p>
      <p>
        3.6. Preregshortcuts Ltd does not and is not responsible for booking any examination with any professional body
        or examination board. It is your responsibility to ensure that you book prior to the relevant closing date any
        exam necessary that you wish to take and which may or may not be associated with the subject matter of the
        services or products provided to you by Preregshortcuts Ltd.
      </p>
      <h4>4. Refund and Cancellation</h4>
      <p>Once a plan is purchased, access cannot be paused or put on hold.</p>
      <p>
        We understand that circumstances may vary, but please note that access to our plans is designed for continuous
        learning and development.
      </p>
      <p>
        If you foresee any potential interruptions, we recommend considering the available plan options or upgrading to
        a plan that aligns with your timeline and needs.
      </p>
      <p>
        If you purchase any plans, courses or services on our website, you agree to pay the specified fees. Refunds may
        be granted under certain circumstances, subject to our refund policy below.
      </p>
      <p>
        Refunds are only available on the essential (combo course) plan and you must attempt the exam in June or
        November 2024 in the year of enrolment. Refunds are not available for any other reasons. Once enrolled you are
        unable to withdraw or cancel the foundation year programme.
      </p>
      <p>
        Unsuccessful in:
        <ul>
          <li>Calculations part 1: £100 refund</li>
          <li>Clinical part 2: £349 refund</li>
          <li>Part 1 & Part 2: £449 refund </li>
          <li>All other courses are exempt from refunds </li>
        </ul>
      </p>
      <p>
        If for any reason any future exam is cancelled, this will count as a pass and no refund will be issued. Refunds
        are issued only if you are unsuccessful in an exam attempt and must be requested by email within 2 weeks of
        results day. Any refunds requested later shall not be authorised.
      </p>
      <p>
        <strong>Workshops:</strong> We are unable to provide refunds on any careers workshops under any circumstances.
        Please note that missed attendance or internet connections are not valid reasons for a refund.
      </p>
      <h4>5. Fees</h4>
      <p>
        5.1. The Fees for the products or services shall be as set out on the Website at the time you placed an order
        for them.
      </p>
      <p>
        5.2. Unless otherwise specified at the time you purchase the products or Services the Fees are inclusive of the
        cost of course materials. Each cost will be set out in the Website prior to your purchase of any products or
        Services.
      </p>
      <p>
        5.3. Save where specifically stated otherwise on the Website, all Fees shall be exclusive of any amounts payable
        to any professional body for registration and examination entry. These are payable by you directly to the
        relevant professional body or examination board and we accept no responsibility or liability for your failure to
        book your exam with the relevant professional body or examination.
      </p>
      <p>
        5.4. Fees for the Service selected by you on the Website shall be debited from your credit / debit card at the
        time of purchase. Fees must be paid in full or arranged instalments prior to you attending any Taught Course or
        accessing any Online Course.
      </p>
      <p>
        5.5. Any fees charged by your debit or credit card provider in connection with your purchase of products or
        services are for your own account and Preregshortcuts Ltd shall not be responsible for these.
      </p>
      <p>
        5.6. You shall be responsible for all costs you incur in connection with your attendance at any Taught Courses
        or your access onto any Online Course.
      </p>
      <h4>6. Liability</h4>
      <p>
        6.1. No part of the provision of the products or services shall be deemed to be, nor is it intended to be, nor
        should it be taken to be, the provision of investment advice.
      </p>
      <p>
        6.2. Although Preregshortcuts Ltd aims to provide the products and services to the highest standards of the
        industry, neither it, nor its trainers accept any liability for (i) any inaccuracy or misleading information
        provided in the programmes or Course Materials and any reliance by trainee on any such information, (ii) any
        loss or corruption of data, (iii) any loss of profit, revenue or goodwill, or (iv) any indirect, special or
        consequential loss arising from any breach of the terms of this Agreement.
      </p>
      <p>
        6.3. Except to the extent that they are expressly set out in these terms and conditions, no conditions,
        warranties or other terms shall apply to our products or services. Subject to clause 6.5 no implied conditions,
        warranties or other terms apply (including any implied terms as to satisfactory quality, fitness for purpose or
        conformance with description).
      </p>
      <p>
        6.4. Subject to clause 6.5 below, Preregshortcuts Ltd's total liability arising from or in connection with these
        terms and conditions and in relation to anything which we may have done or not done in connection with these
        terms and conditions and the delivery of the Service (and whether the liability arises because of breach of
        contract, negligence or for any other reason) shall be limited to the Fees received by us in connection with the
        relevant Online Course or Taught Course in relation to which a dispute has arisen.
      </p>
      <p>
        6.5. Nothing in this Agreement shall exclude or limit Preregshortcuts Ltd's liability for (i) death or personal
        injury caused by negligence, (ii) fraudulent misrepresentation or (iii) any other matter which under English law
        may not be limited or excluded.
      </p>
      <p>
        6.6. No claim may be brought more than six months after the last date on which the products or services
        concerned have finished or ceased to be provided by us.
      </p>
      <h4>7. Intellectual Property</h4>
      <p>
        7.1. All Intellectual Property Rights in the Course Materials, Online Courses and the speeches made by trainers
        at the Taught Courses are, and remain, the intellectual property of Preregshortcuts Ltd or its licensors,
        whether adapted, written for or customised for the Client or not.
      </p>
      <p>
        7.2. You are not authorised to:{" "}
        <List
          items={[
            "copy, modify, reproduce, re-publish, sub-licence, sell, upload, broadcast, post, transmit or distribute any of the Course Materials without prior written permission;",
            "record on video or audio tape, relay by videophone or other means the Online Course or Taught Course given",
            "use the Course Materials in the provision of any other course or training whether given by us or any third party trainer;",
            "remove any copyright or other notice of Preregshortcuts Ltd on the Course Materials;",
            "modify, adapt, merge, translate, disassemble, decompile, reverse engineer (save to the extent permitted by law) any software forming part of the Online Courses.",
          ]}
        />
      </p>
      <p>
        Breach by you of this clause 7.2 shall allow us to immediately terminate these terms and conditions with you and
        cease to provide you with any products or services, including but not limited to access to the Online Courses.
      </p>
      <p>
        7.3. In consideration of the Fees paid by you, we grant to you a limited, non-transferable, non-exclusive
        licence to use the Course Materials and the software in respect of the Online Course for the sole purpose of
        completing the Online Course and / or attending the Taught Course.
      </p>
      <h4>8. Confidentiality</h4>
      <p>
        8.1. Each party shall keep the other party's Confidential Information strictly confidential and not use it
        otherwise than for the purposes of these terms and conditions, and shall return it on demand and not retain
        copies of it.
      </p>
      <p>
        8.2. Either party may disclose Confidential Information to its legal and other advisors for the purposes of
        obtaining advice from them.
      </p>
      <p>8.3. This clause shall continue notwithstanding termination of these terms and conditions.</p>
      <h4>9. Termination</h4>
      <p>
        9.1. We shall be entitled to terminate these terms and conditions and cease to provide you with any products or
        Services with immediate effect in the event that you:
        <List
          items={[
            "fail to pay when due your Fees;",
            "act in an aggressive, bullying, offensive, threatening or harassing manner towards any employee of Preregshortcuts Ltd, any tutor or staff who provides the Taught Courses or online courses  or any student who attends any Taught or online Course;",
            "steal or act in fraudulent or deceitful manner towards us or our employees or any other students who may be on our premises or attending our Taught Courses;",
            "intentionally or recklessly damage our property or the property of our employees or other students attending our premises;",
            "are intoxicated through alcohol or illegal drugs while on our premises;",
            "commit any criminal offence committed on our premises or where the victim is our employee or student;",
            "are in breach of these terms and conditions.",
          ]}
        />
      </p>
      <p>
        9.2. On termination clause 6 (liability), 7 (intellectual property rights), 8 (confidentiality) and 10
        (restrictions) shall continue notwithstanding such termination.
      </p>
      <h4>10. Assignment</h4>
      <p>
        Any products or services provided by us under these terms and conditions are personal to you and cannot be
        transferred or assigned to any other person.
      </p>
      <h4>11. Entire Agreement</h4>
      <p>
        These terms and conditions, together with the Website Disclaimer and Course Specific Terms and Conditions are
        the entire agreement between the parties and supersede any prior agreements and arrangements, whether written or
        oral. You confirm that you have not relied on any representations in entering into these and any other terms and
        conditions with us. Nothing in this clause or terms and conditions shall limit liability for any fraudulent
        misrepresentation.
      </p>
      <h4>12. Force Majeure</h4>
      <p>
        Preregshortcuts Ltd shall not be liable to you for any breach of its obligations or termination under these
        terms and conditions arising from causes beyond its reasonable control, including, but not limited to, fires,
        floods, earthquakes, volcanoes and other Acts of God, terrorism, strikes, delay caused by transport disputes,
        failure to provide a course caused by a death in the trainer's family, illness of the trainer, Government edict
        or regulation.
      </p>
      <h4>13. Assignment</h4>
      <p>
        We may assign, transfer, sub-contract any of our rights or obligations to any third party at our discretion.
      </p>
      <h4>14. Data Protection</h4>
      <p>
        14.1 The nature of the Services provided by us means that we will obtain, use and disclose (together "Use")
        certain information about you ("Data"). This statement sets out the principles governing our Use of Data. By
        purchasing the Services you agree to this Use.
      </p>
      <p>
        14.2 When you register with us you will need to provide certain Data such as your contact details and
        demographic information. We will store this Data and use it to contact you, provide you with details of the
        services or products you have purchased and otherwise as required during the normal provision of the course.
      </p>
      <p>
        14.3 We may also use the above Data, and similar Data you provide us in response to surveys, to aggregate user
        profiles and, unless you click on the relevant button on the Registration Form, provide you with communications.
        We will not pass any personal data onto anyone outside of Preregshortcuts Ltd
      </p>
      <p>
        14.4 To enable us to monitor and improve our Services, we gather certain aggregated information about you,
        including details of your operating system, browser version, domain name and IP address, the URL you came from
        and go to and the parts of the Website you visit.
      </p>
      <p>
        14.5. We use information such as your User ID, session identifiers and password to enable us to identify whether
        you are using our services, assist with the provision of services and to ensure that you have access to relevant
        products. We will only read cookies from your cookie file placed there through your web browser's interaction
        with the Website.
      </p>
      <p>
        14.6. Our products may link to third party websites and we are not responsible for their data policies or
        procedures or their content.
      </p>
      <p>
        14.7. Preregshortcuts Ltd endeavour to take all reasonable steps to protect your personal Data including the use
        of encryption technology, but cannot guarantee the security of any Data you disclose. You accept the inherent
        security implications of being and transacting on-line over the internet and will not hold us responsible for
        any breach of security.
      </p>
      <p>
        14.8. Preregshortcuts Ltd may supplement the information that you provide with information we receive from third
        parties, such as exam registration bodies or your employer.
      </p>
      <p>14.9. If you wish to change or update the data we hold about you, please email : info@preregshortcuts.com</p>
      <h4>15. Law and Jurisdiction</h4>
      <p>
        This Agreement is subject to English law and the parties submit to the exclusive jurisdiction of the English
        courts in connection with any dispute hereunder.
      </p>
      <h4>16. TRAINEES GDPR RIGHTS</h4>
      <p>
        If you are within the European Union, you are entitled to certain information and have certain rights under the
        General Data Protection Regulation. Those rights include:
      </p>
      <p>
        We will retain the any information you choose to provide to us until the earlier of: (a) you asking us to delete
        the information, (b) our decision to cease using our existing data providers, or (c) the Company decides that
        the value in retaining the data is outweighed by the costs of retaining it.
      </p>
      <p>
        You have the right to request access to your data that the Company stores and the rights to either rectify or
        erase your personal data.
      </p>
      <p>You have the right to seek restrictions on the processing of your data.</p>
      <p>You have the right to object to the processing of your data and the right to the portability of your data.</p>
      <p>
        To the extent that you provided consent to the Company's processing of your personal data, you have the right to
        withdraw that consent at any time, without affecting the lawfulness of processing based upon consent that
        occurred prior to your withdrawal of consent.
      </p>
      <p>
        You have the right to lodge a complaint with a supervisory authority that has jurisdiction over issues related
        to the General Data Protection Regulation.
      </p>
      <p>
        We require only the information that is reasonably required to enter into a contract with you. We will not
        require you to provide consent for any unnecessary processing as a condition of entering into a contract with
        us.
      </p>
      <h4>17. Unsubscribing</h4>
      <p>
        You may unsubscribe from our marketing communications by clicking on the “unsubscribe” link located on the
        bottom of our e-mails, or by sending us an email to info@preregshortcuts.com
      </p>
      <h4>18. Notices</h4>
      <p>You can contact us by the following method: Email: info@preregshortcuts.com</p>
      <h4>19. User responsibilities</h4>
      <List
        items={[
          "You will treat all other users and instructors with respect and courtesy.",
          "You will not engage in any form of harassment, discrimination, or offensive behavior towards others.",
          "You will not use the platform to promote or engage in any illegal, harmful, or unethical activities.",
        ]}
      />
      <p>Payment and Refunds</p>
      <List
        items={[
          "If you choose to purchase any paid courses or services on the platform, you agree to pay the specified fees in a timely manner.",
          "Refunds and cancellations will be provided according to our refund and cancellation policy. ",
        ]}
      />
      <p>Technical Requirements</p>
      <List
        items={[
          "You are responsible for ensuring that your device, internet connection, and software meet the minimum technical requirements to access and use our e-learning platform.",
          "We are not responsible for any technical issues or limitations that may prevent you from accessing or using the platform.",
        ]}
      />
      <p>Limitation of Liability</p>
      <List
        items={[
          "We strive to provide accurate and reliable content, but we do not guarantee the accuracy, completeness, or usefulness of any information on the platform.",
          "We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform or any content provided therein. ",
        ]}
      />
      <p>Termination</p>
      <List
        items={[
          "We reserve the right to terminate or suspend your account at any time, without prior notice, if you violate our user responsibility terms and conditions.",
          "Upon termination, you will no longer have access to the platform or any content associated with your account.",
        ]}
      />

      <h4>20. Access and Account Security</h4>

      <List
        items={[
          "You must be at least 18 years old or have the consent of a parent or guardian to create an account.",
          "You are responsible for providing accurate and up-to-date information during the account creation process.",
          "You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
        ]}
      />

      <h4>21. Content ownership and use</h4>

      <List
        items={[
          "All content provided on our e-learning platform, including but not limited to courses, videos, documents, and quizzes, is for personal, non-commercial use only.",
          "You may not copy, distribute, modify, or reproduce any content from the platform without obtaining prior written permission from the platform owner.",
          "You are solely responsible for any content you upload, post, or share on any of our course platforms, and you agree not to infringe upon any intellectual property rights or violate any laws in doing so.",
        ]}
      />

      <h4>22. Copyright and intellectual property</h4>

      <List
        items={[
          "All Preregshortcuts courses, including all its content, materials, and resources, is the intellectual property of Preregshortcuts or the organization providing the course.",
          "All copyrights, trademarks, and other intellectual property rights associated with the course are reserved and protected by applicable laws.",
        ]}
      />

      <p>Permitted Use:</p>

      <List
        items={[
          "The course materials are provided to you solely for your personal, non-commercial use.",
          "You may access, view, and use the course materials for educational purposes only.",
          "You may not copy, distribute, modify, reproduce, or create derivative works from the course materials without obtaining prior written permission from the course owner.",
        ]}
      />

      <p>Prohibited Use:</p>

      <List
        items={[
          "You may not share, sell, rent, or sublicense the course materials to any third party.",
          "You may not use the course materials for any commercial purposes without obtaining prior written permission from Preregshortcuts Ltd",
          "You may not remove, alter, or obscure any copyright notices or other proprietary rights notices from the course materials.",
        ]}
      />

      <p>Trainee uploads on our learning platforms:</p>

      <List
        items={[
          "If you upload content to our telegram group, you retain ownership of your content.",
          "By submitting or uploading content on our platforms, you grant Preregshortcuts a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and distribute your content for the purpose of providing the course and improving the platform.",
        ]}
      />

      <p>Copyright Infringement:</p>

      <List
        items={[
          "If you believe that any content on the e-learning platform infringes upon your copyright, please notify info@preregshortcuts.com  promptly.",
          "Preregshortcuts owners will investigate and take appropriate action, which may include removing the infringing content from the platform.",
        ]}
      />

      <p>Disclaimer:</p>

      <List
        items={[
          "The course materials are provided on an 'as is' basis, without any warranties or guarantees of any kind, whether express or implied.",
          "The course owner does not warrant that the course materials will be error-free, uninterrupted, or suitable for your specific needs.",
        ]}
      />

      <p>Limitation of Liability:</p>

      <List
        items={[
          "Preregshortcuts Ltd and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the course materials or the e-learning platform.",
          "In no event shall the total liability of Preregshortcuts Ltd exceed the amount paid by you, if any, for accessing the course.",
        ]}
      />

      <h4>23. Privacy policy</h4>

      <p>Refer to our privacy policy on our website</p>

      <h4>24. Dispute resolution</h4>

      <ol>
        <li>
          <p>Purpose</p>
          <p>
            The purpose of this dispute resolution policy is to provide a fair and efficient process for resolving any
            disputes or conflicts that may arise between our users and Preregshortcuts Ltd
          </p>
        </li>
        <li>
          <p>Informal Resolution</p>
          <List
            items={[
              "In the event of a dispute, users are encouraged to first attempt to resolve the issue informally by communicating directly with our support team at info@preregshortcuts.com.",
              "Users should provide a clear description of the issue, any relevant evidence or documentation, and their desired resolution.",
              "Our support team will make reasonable efforts to address and resolve the dispute in a timely manner.",
            ]}
          />
        </li>
        <li>
          <p>Mediation and arbitration</p>
          <List
            items={[
              "If the dispute cannot be resolved informally, both parties may agree to engage in mediation or arbitration with the subsequent decision and outcome being final",
              "The dispute shall be governed by and construed in accordance with the laws of the jurisdiction in which Preregshortcuts Ltd is based.",
              "Any legal action or proceeding shall be brought exclusively in the courts of that jurisdiction",
            ]}
          />
        </li>
        <li>
          <p>Limitation of Liability</p>
          <p>
            Preregshortcuts Ltd and its affiliates shall not be liable for any damages, losses, or expenses arising from
            the dispute resolution process, except in cases of willful misconduct or gross negligence.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default FullTnC;

const List = ({ items }) => {
  return (
    <ul style={{ listStyle: "lower-alpha" }}>
      {items.map((item, idx) => (
        <li key={idx}>{item}</li>
      ))}
    </ul>
  );
};
