import { Grid, Typography } from "@mui/material";

import RenderTutor from "src/newComponents/RenderTutor";

import Course from "./Course";
import CourseModel from "src/models/CourseModel";

export default function ElearningCourseDetailsSummary({ course }: { course: CourseModel }) {
  return (
    <Grid container direction="row-reverse" spacing={3}>
      <RenderTutor tutor={course.tutor} shortBioWithLearnMore={false} />

      <Grid item xs={12} sm={8}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          Course
        </Typography>

        <Course course={course} simplifiedView={false} />
      </Grid>
    </Grid>
  );
}
