import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import LoadingScreen from "src/components/loading-screen/LoadingScreen";

import ElearningCourseView from "./components/ElearningCourseView";
import CourseRepository from "src/repository/courseRepository";
import CourseModel from "src/models/CourseModel";

const CoursePage = () => {
  const { courseSlug } = useParams();

  const [course, setCourse] = useState<CourseModel>();
  const [isLoading, setIsLoading] = useState(true);

  const courseRepository = new CourseRepository();

  useEffect(() => {
    if (courseSlug) {
      courseRepository
        .getCourse(courseSlug)
        .then((course) => setCourse(course))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSlug]);

  const canonicalUrl = "/courses/" + courseSlug;

  return (
    <>
      <Helmet link={[{ rel: "canonical", href: canonicalUrl }]}>
        <title>{course ? course.title : courseSlug} | Pre-Reg Shortcuts</title>
        {course && (
          <meta
            name="description"
            content={`${course.description.slice(0, 150)}${course.description.length > 150 ? "..." : ""}`}
          />
        )}
        <meta
          name="keywords"
          content="Registration assessment outcomes, Therapeutic areas (high medium and low), High risk drugs, paediatrics, calculations"
        />
      </Helmet>
      {isLoading && <LoadingScreen />}
      {course && <ElearningCourseView course={course} />}
    </>
  );
};

export default CoursePage;
