import React, { useState } from "react";
import { Button } from "@mui/material";

import MoreInformationModal from "../MoreInformationModal";

interface LearnMoreButtonWithModalProps {
  title: string;
  content: React.ReactNode;
  rightMargin?: boolean;
}

const LearnMoreButtonWithModal = ({ title, content, rightMargin }: LearnMoreButtonWithModalProps) => {
  const [open, setOpen] = useState(false);

  const style = rightMargin ? { marginRight: "1rem" } : {};

  return (
    <>
      <Button variant="outlined" color="secondary" style={style} onClick={() => setOpen(true)} size="small">
        Learn More
      </Button>
      {open && <MoreInformationModal title={title} content={content} handleClose={() => setOpen(false)} />}
    </>
  );
};

export default LearnMoreButtonWithModal;
