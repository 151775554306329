import AnswerLink from "src/config/faq/answer/AnswerLink";

const Link = ({ href, pageTitle }) => {
  return (
    <a href={`/${href}/`} className="faqPageLink" target="_blank" rel="noreferrer">
      {pageTitle}
      {pageTitle !== "Enrol Now" && <> page</>}
    </a>
  );
};

const HowItWorksContent = () => {
  return (
    <div>
      <p>Who can benefit from Pre-Reg Shortcuts?</p>

      <p>
        Our exam revision courses & Foundation Pharmacist Training Programme is suitable for anyone in Pharmacy
        including Pharmacy students, Trainee Pharmacists & Pharmacists too!
      </p>

      <p>
        All our programmes & courses are mapped to the GPhC framework making them suitable for anyone interested in
        revising for the GPhC registration assessment and for university exams too
      </p>

      <p>
        You can join today, or any time with full access to the programme / exam revision courses you choose for 365
        days!
      </p>

      <p>
        To learn more and join our programme / exam revision courses{" "}
        <AnswerLink href="/products/">click here</AnswerLink> and follow the steps below:
      </p>

      <p>
        <strong>Step 1</strong>
      </p>
      <p>
        Browse our <Link href="foundation-training-programme" pageTitle={"Foundation Pharmacist Training Programme"} />{" "}
        & <Link href="courses" pageTitle={"Exam Revision Courses"} />.
      </p>
      <p>
        <strong>Step 2</strong>
      </p>
      <p>
        Select your Programme/Exam Revision Courses and add to basket, follow on screen instructions to create your
        account.
      </p>
      <p>
        <strong>Step 3</strong>
      </p>
      <p>
        Complete purchase and follow the email instructions, create your password, login and access our programme / exam
        revision courses!
      </p>

      <p style={{ marginTop: "2rem" }}>
        Any technical issues? View our <Link href="faq" pageTitle={"FAQ"} /> and if you can't find your answer, contact
        our support team!
      </p>

      <p>
        Early bird discount: £200 discount on the Foundation Pharmacist Training Programme until the 30th August 2024!
        Use code FTY2024
      </p>
    </div>
  );
};

export default HowItWorksContent;
