import { useState } from "react";

import { Typography } from "@mui/material";
import LessonItem from "./LessonItem";
import LessonsDialog from "./LessonsDialog";

export default function LessonList({ lessons, simplifiedView }) {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [isDescExpanded, setIsDescExpanded] = useState(false);

  const isPlaying = selectedLesson !== null;

  const handleExpanded = (panel) => (event, isExpanded) => {
    setIsDescExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {!simplifiedView && (
        <Typography variant="h6" sx={{ mb: 1 }}>
          Lessons
        </Typography>
      )}

      {lessons?.map((lesson) => (
        <LessonItem
          key={lesson.id}
          lesson={lesson}
          selected={isPlaying && selectedLesson && selectedLesson.id === lesson.id}
          expanded={isDescExpanded === lesson.id}
          onExpanded={handleExpanded(lesson.id)}
          onOpen={() => setSelectedLesson(lesson)}
          simplifiedView={simplifiedView}
        />
      ))}

      {selectedLesson && (
        <LessonsDialog
          open={selectedLesson !== null}
          onClose={() => setSelectedLesson(null)}
          selectedLesson={selectedLesson}
          disableProgress={false}
        />
      )}
    </>
  );
}
