import { Container } from "@mui/material";

import ElearningCourseDetailsHero from "./ElearningCourseDetailsHero";
import ElearningCourseDetailsSummary from "./ElearningCourseDetailsSummary";
import CourseModel from "src/models/CourseModel";

export default function ElearningCourseView({ course }: { course: CourseModel }) {
  return (
    <>
      <ElearningCourseDetailsHero course={course} />

      <Container
        sx={{
          overflow: "hidden",
          pt: { xs: 5, md: 10 },
          pb: { xs: 15, md: 10 },
        }}
      >
        <ElearningCourseDetailsSummary course={course} />
      </Container>
    </>
  );
}
