import { FAQSection } from "../faqPageData";
import Answer from "./answer/Answer";
import AnswerLink from "./answer/AnswerLink";

export const programmeEnrolmentAndAccessTopics: FAQSection[] = [
  {
    title: "Programme Enrolment and Access",
    content: [
      {
        id: "best-time-to-enrol",
        question: "When Is The Best Time To Enrol On Your Programme / Exam Revision Courses?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Our Foundation Pharmacist Training Programme / Exam Revision Courses, are designed in a way so you can
              enrol anytime. You will receive exactly 365 days access from the day you enrol giving you plenty of time
              to cover the content in your account.
            </p>

            <p>
              <strong>Foundation Pharmacist Training Programme</strong>
            </p>
            <p>
              Receive all content straight away, live study sessions are uploaded to your account after they take place
            </p>

            <p>
              <strong>GPhC Exam Revision Courses</strong>
            </p>
            <p>
              These are fully recorded courses with workbooks so you have access to everything you need straight away in
              your account
            </p>
            <p>We've designed our programme / exam revision courses so you have full access straight away.</p>
            <p style={{ marginBottom: 0 }}>
              You can join any time however we do have a £200 discount on the Foundation Pharmacist Training Programme
              using code FTY2024 expiry 31st August 2024.
            </p>
          </div>
        ),
      },
      {
        id: "how-it-works",
        question: "What Does Your Programme Cover & How Do You Support Me?",
        answer: (
          <>
            <Answer
              lines={[
                "Our Foundation Pharmacist Training Programme is our all in one programme designed so that you have all the support you need in your foundation training year through our platform. Our Programme is a perfect blend of:",
                "Exam Revision Courses: Interactive recorded lessons for all topics for you to access and revise anytime for Calculations, BNF, MEP, OTC.",
                "Live Online Study Sessions: Over 50 opportunities to connect with our tutors throughout the year.",
                "Including our Question Bank with over 1000 questions & Mock Paper",
                "Option Additional Training includes Vaccination, First Aid & Leadership Training working with leading training providers ECG & NPA",
                <>
                  We're not done yet there's plenty more! To view our Programme{" "}
                  <AnswerLink href="/foundation-training-programme/">click here</AnswerLink> and{" "}
                  <AnswerLink href="/how-it-works/">How It Works</AnswerLink>.
                </>,
                <>
                  We also have <AnswerLink href="/courses">Exam Revision Courses</AnswerLink> for Trainees/Students
                  who'd like to enrol on separate revision courses for Calculations, BNF, MEP, OTC.
                </>,
              ]}
            />
          </>
        ),
      },
      {
        id: "pharmacy-owner-enrolment",
        question:
          "I am a Pharmacy Contractor/Organisation, Can I Enrol My Trainee/s on your Foundation Pharmacist Training Programme",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Absolutely! We're already supporting the foundation Pharmacist training programme with many pharmacies
              including companies such as Jardines & Paydens.
            </p>
            <p>
              Our programme includes full support for the Trainee Pharmacist (Pre-Reg) & Designated Supervisor (Tutor).
            </p>
            <p>
              If you enrol your Trainee onto our programme we'll include our Designated Supervisor Programme for FREE.
            </p>
            <p>
              Contact our team at{" "}
              <a href="mailto:info@preregshortcuts.com" className="faqPageLink" target="_blank" rel="noreferrer">
                info@preregshortcuts.com
              </a>{" "}
              to discuss requirements, support available and how we can help your Pharmacy.
            </p>
          </div>
        ),
      },
      {
        id: "fyp2",
        question:
          "I Am A Trainee Pharmacist Already Enrolled With Another Foundation Training Provider, Can I Enrol On Your Programme?",
        answer: (
          <>
            Yes, please speak to your Pharmacy and ask to change to our programme, alternatively you'll have the
            opportunity to enrol on our{" "}
            <AnswerLink href="/foundation-training-programme/">Foundation Pharmacist Training Programme</AnswerLink> or{" "}
            <AnswerLink href="/courses/">Exam Revision Courses</AnswerLink> individually.
          </>
        ),
      },
      {
        id: "where-can-i-purchase-combo-course",
        question: "Where Can I Purchase Your Combo Course?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Our popular combo course (enrolled on by 1 Trainee Pharmacist in every 4 nationwide) has gone through a
              revamp, listening to feedback we have decided to separate the Combo Course into{" "}
              <AnswerLink href="/courses/calculations-course">Calculations Course</AnswerLink> and{" "}
              <AnswerLink href="/courses/bnf-course">BNF Course</AnswerLink>.
            </p>
            <p style={{ marginBottom: 0 }}>
              Therefore to access the Combo Course please enrol on the Calculations and BNF Course, you will receive 365
              days access from the day you enrol.
            </p>
          </div>
        ),
      },
      {
        id: "free-preview-of-programme",
        question: "Do You Offer A FREE Preview To Your Foundation Pharmacist Training Programme?",
        answer: (
          <>
            <Answer
              lines={[
                <>
                  View our <AnswerLink href="/courses/">Exam Revision Courses</AnswerLink>, select your course
                  (Calculations/BNF/MEP/OTC), then chapter and you'll be able to play and watch our lessons.
                </>,
                <>
                  Trial our <AnswerLink href="/question-bank/">Question Bank</AnswerLink> and select Try
                  Calculations/Try Clinical.
                </>,
                <>
                  Full Foundation Pharmacist Training Programme details{" "}
                  <AnswerLink href="/foundation-training-programme/">here</AnswerLink>.
                </>,
              ]}
            />
          </>
        ),
      },
      {
        id: "enrolment",
        question: "How Can I Enrol On Your Programme & Access My Account?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Our Foundation Pharmacist Training Programme is most popular however you'll be able to view your options
              on our <AnswerLink href="/products/">Products page</AnswerLink>.
            </p>
            <p>The process is simple:</p>
            <ol>
              <li>Purchase our Foundation Pharmacist Training Programme/ Exam Revision Courses.</li>
              <li>Follow the instructions in your email, create your login details.</li>
              <li>Log in to your account and access our programme / exam revision courses.</li>
            </ol>
            <p style={{ marginBottom: 0 }}>
              Everything in your purchase will be accessible in your account after logging in and you'll receive 365
              days access from the day you enrol.
            </p>
          </div>
        ),
      },
      {
        id: "tr1",
        question: "What Do I Need To Access Your Programme & Exam Revision Courses?",
        answer: (
          <div>
            <p style={{ marginTop: "0" }}>You will need a computer/laptop with a stable internet connection.</p>
            <p style={{ marginBottom: 0 }}>
              Using web browsers Google Chrome or Mozilla Firefox only for the best experience.
            </p>
          </div>
        ),
      },
      {
        id: "access-on-multiple-devices",
        question: "Can I Access The Programme On Multiple Devices?",
        answer: <>We recommend logging in from one device at a time to prevent any issues with your account.</>,
      },
      {
        id: "slides-mock-afterwards",
        question: "Do I Receive Workbooks/Videos?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>
              Due to copyright we cannot provide the slides in the exam revision courses and the videos are not
              available for download under any circumstances. Brand new for this year, please use the workbooks in your
              account available in every chapter to complete alongside the GPhC exam revision courses.
            </p>
            <p style={{ marginBottom: 0 }}>
              Our years of experience with a 95% pass rate in GPhC exams for almost a decade, shows using the workbooks
              and watching our interactive lessons is the recipe for success.
            </p>
          </div>
        ),
      },
    ],
  },
];
