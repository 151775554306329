import { FAQSection } from "../faqPageData";

export const refundAndCancellationTopics: FAQSection[] = [
  {
    title: "Refund and Cancellation Policy",
    content: [
      {
        id: "rac1",
        question: "Refunds and Extension Policy",
        answer: (
          <div>
            <p style={{ marginTop: "0" }}>
              All sales are final at purchase, it's the responsibility of the student/trainees to ensure the programme
              materials are accessed within their allocated access duration time.
            </p>
            <p>
              <strong>For purchases after 31st May 2024</strong>
            </p>
            <p>
              Once purchased, there is no option to pause or withdraw from the foundation pharmacist training programme.
              We have a no refund policy.
            </p>
            <p>
              If you are unsuccessful in the GPhC Exam and enrolled on the foundation pharmacist training programme, we
              will give you a free extension to the next GPhC Exam.
            </p>
            <p>
              If you have enrolled on the exam revision courses/mock/question bank/workshops we have a no refund policy
              and there is no refund available.
            </p>

            <p>
              <strong>For purchases before 31st May 2024</strong>
            </p>
            <p>
              Once purchased, there is no option to pause or withdraw from the foundation pharmacist training programme
              or exam revision courses. It's the responsibility of the student student/trainee to ensure programme
              materials are accessed.
            </p>
            <p>
              Refund policy for Essential, Standard and Premium Plan. The refund amount is based on the Combo Course
              available in all 3 plans (Clinical & Calculations Exam) which has a value of £449:
            </p>

            <p>
              <strong>Unsuccessful</strong> in:{" "}
            </p>
            <ul>
              <li>Calculations part 1: £100 refund</li>
              <li>Clinical part 2: £349 refund</li>
              <li>Part 1 & Part 2: £449 refund</li>
            </ul>
            <p>The maximum refund is £449 regardless of the plan you enrol on.</p>
            <p>
              Please note that once enrolled, refunds are not provided unless you have an unsuccessful attempt at the
              June or November 2024 exam.
            </p>
            <p style={{ marginBottom: 0 }}>
              Refunds must be requested within 2 weeks of your exam attempt. For more detailed information on our refund
              policy, please refer to our{" "}
              <a href="/terms-and-conditions/" className="faqPageLink" target="_blank">
                Terms & Conditions page
              </a>
              .
            </p>
          </div>
        ),
      },
      {
        id: "rac2",
        question:
          "Can I Withdraw From Exam Revision Courses, Question Bank, Mock, Workshops, What Is Your Refund Policy?",
        answer: (
          <div>
            <p style={{ marginTop: 0 }}>After 31st May 2024</p>
            <p>
              Unfortunately there is no option to withdraw after purchase. There are no free extensions, any extensions
              will need to be purchased through your account. All sales are final.
            </p>
            <p style={{ marginBottom: 0 }}>
              We provide 365 days access from the day you enrol so there is ample time to access your account and any
              resources purchased.
            </p>
          </div>
        ),
      },
    ],
  },
];
