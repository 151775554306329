import CourseModel from "src/models/CourseModel";
import Chapter from "./Chapter";
import DesignatedSupervisorProgrammeModel from "src/models/DesignatedSupervisorProgrammeModel";

const Course = ({
  course,
  simplifiedView,
}: {
  course: CourseModel | DesignatedSupervisorProgrammeModel;
  simplifiedView: boolean;
}) => {
  const numUniqueWeights = numUniqueWeightsInCourse(course);
  const showChapterWeight = numUniqueWeights > 1;

  return (
    <>
      {course.chapters.map((chapter, chapterIdx) => {
        return (
          <Chapter
            key={chapter.id}
            chapter={chapter}
            chapterIdx={chapterIdx}
            showChapterWeight={showChapterWeight}
            simplifiedView={simplifiedView}
          />
        );
      })}
    </>
  );
};

export default Course;

const numUniqueWeightsInCourse = (course: CourseModel | DesignatedSupervisorProgrammeModel) => {
  const allWeightsInCourse = course.chapters.map((chapter) => chapter.weight);
  const weightsAsSet = new Set(allWeightsInCourse);
  return weightsAsSet.size;
};
