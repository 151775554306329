import { Divider, Typography } from "@mui/material";

import CustomAccordion from "src/custom_components/courses/CustomAccordion";
import ChapterResource from "src/custom_components/courses/ChapterResource";
import ChapterWeight from "src/custom_components/courses/ChapterWeight";
import RenderTextAreaContent from "src/custom_components/RenderTextAreaContent";

import LessonList from "./LessonList";
import ChapterModel from "src/models/ChapterModel";

interface ChapterProps {
  chapter: ChapterModel;
  chapterIdx: number;
  showChapterWeight: boolean;
  simplifiedView: boolean;
}

const Chapter = ({ chapter, chapterIdx, showChapterWeight, simplifiedView }: ChapterProps) => {
  if (simplifiedView) {
    return (
      <div style={{ marginBottom: "3rem" }}>
        <AccordionTitle
          chapter={chapter}
          chapterIdx={chapterIdx}
          showChapterWeight={showChapterWeight}
          simplifiedView={simplifiedView}
        />
        <LessonList lessons={chapter.lessons} simplifiedView={simplifiedView} />
        <Divider sx={{ mt: 1 }} style={{ borderColor: "transparent" }} />
        <RenderResources chapter={chapter} />
      </div>
    );
  }
  return (
    <div style={{ marginBottom: "2rem", borderBottom: "1px solid lightgrey" }}>
      <CustomAccordion
        removeMargin={false}
        key={chapter.id}
        title={
          <AccordionTitle
            chapter={chapter}
            chapterIdx={chapterIdx}
            showChapterWeight={showChapterWeight}
            simplifiedView={simplifiedView}
          />
        }
        content={
          <>
            <LessonList lessons={chapter.lessons} simplifiedView={simplifiedView} />
            <RenderResources chapter={chapter} />
          </>
        }
      />
    </div>
  );
};

export default Chapter;

function AccordionTitle({
  chapter,
  chapterIdx,
  showChapterWeight,
  simplifiedView,
}: {
  chapter: ChapterModel;
  chapterIdx: number;
  showChapterWeight: boolean;
  simplifiedView: boolean;
}) {
  const title = simplifiedView ? "Module" : "Chapter";
  return (
    <div>
      <div style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
        <Typography variant="h5" sx={{ mb: 0 }}>
          {title} {chapterIdx + 1}: {chapter.title}
        </Typography>
        {showChapterWeight && <ChapterWeight chapter={chapter} />}
      </div>
      <RenderTextAreaContent content={chapter.description} />
    </div>
  );
}

function RenderResources({ chapter }: { chapter: ChapterModel }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      {chapter.resource_description && chapter.accessible_resource_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description}
          accessibleResourceUrl={chapter.accessible_resource_url}
          isProgrammeResource={false}
        />
      )}
      {chapter.resource_description_2 && chapter.accessible_resource_2_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description_2}
          accessibleResourceUrl={chapter.accessible_resource_2_url}
          isProgrammeResource={false}
        />
      )}
      {chapter.resource_description_3 && chapter.accessible_resource_3_url && (
        <ChapterResource
          resourceDescription={chapter.resource_description_3}
          accessibleResourceUrl={chapter.accessible_resource_3_url}
          isProgrammeResource={false}
        />
      )}
    </div>
  );
}
