import React, { useContext, useState } from "react";
import { Button, Typography } from "@mui/material";

import { AppContext } from "src/context/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { errorToast, successToast } from "../toast";
import useIsLightMode from "src/hooks/useIsLightMode";
import { reportQuestionEndpoint } from "src/urls";
import axios from "axios";

import { deviceDetect } from "react-device-detect";

const ReportQuestion = ({ questionId }) => {
  const { isAuthenticated } = useContext(AppContext);

  const isLightMode = useIsLightMode();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [problem, setProblem] = useState("");
  const [isReporting, setIsReporting] = useState(false);

  if (!isAuthenticated) return <></>;

  const handleClose = () => {
    setIsModalVisible(false);
    setProblem("");
  };

  const reportProblem = () => {
    if (problem.length <= 12) {
      errorToast("Please describe the problem in more detail.", isLightMode);
      return;
    }

    if (isReporting) return;

    setIsReporting(true);

    const deviceData = JSON.stringify(deviceDetect());

    axios
      .post(reportQuestionEndpoint, {
        question_id: questionId,
        problem: problem,
        device_blob: deviceData,
      })
      .then(() => {
        setIsReporting(false);

        setIsModalVisible(false);
        setProblem("");
        successToast("Thank you for reporting this problem, we will look into it as soon as possible.", isLightMode);
      })
      .catch(() => {
        setIsReporting(false);
        errorToast("Failed to report problem, please try again later.", isLightMode);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          marginTop: "1rem",
        }}
      >
        <Typography>Found a problem with this question?</Typography>
        <Button
          size="small"
          variant="contained"
          color="warning"
          sx={{ height: "24px", ml: 1 }}
          onClick={() => setIsModalVisible(true)}
        >
          Report
        </Button>
      </div>
      {isModalVisible && (
        <Dialog onClose={handleClose} aria-labelledby="report-question" open={true} fullWidth={true} maxWidth="xs">
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ paddingRight: "2.75rem", paddingBottom: "0px" }}>
            <span>Report Question</span>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              paddingBottom: 0,
              marginBottom: "1rem",
              paddingLeft: "1rem",
            }}
          >
            <div style={{ marginTop: "1rem" }}>
              <div style={{ marginBottom: "1rem" }}>
                <Typography sx={{ mb: 2 }}>We're sorry to hear there's a problem with this question.</Typography>
                <Typography>Please describe the problem below:</Typography>
              </div>

              <div style={{ marginBottom: "1rem" }}>
                <TextField
                  inputProps={{ minLength: 6 }}
                  rows={2}
                  maxRows={4}
                  multiline
                  id="problem"
                  label="Describe the problem"
                  name="problem"
                  value={problem}
                  onChange={(e) => setProblem(e.target.value)}
                  fullWidth
                />
              </div>

              <div
                style={{
                  float: "right",
                  marginTop: "1rem",
                  marginBottom: "3px",
                }}
              >
                <Button style={{ marginRight: "1rem" }} variant="outlined" color="inherit" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  type="submit"
                  onClick={reportProblem}
                  isDisabled={isReporting}
                >
                  Report
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ReportQuestion;
