import React, { useContext, useEffect, useState, Suspense } from "react";

import { AppContext } from "../context/AppContext";
import { saveLessonProgressEndpoint } from "../urls";
import axios from "axios";

const VidstackPlayer = React.lazy(() => import("src/newComponents/VidstackPlayer"));

// return True if the last save was longer than 1 minute ago
const getWasLastSaveLongerThanOneMinuteAgo = (lastSave) => {
  // there was no last save, so yes, longer than 1 min ago
  if (lastSave === null) return true;

  // there was a last save, return True if it was >= 60s ago!
  const now = new Date().getTime();
  const diff = now - lastSave.getTime();
  const diffInSeconds = diff / 1000;
  return diffInSeconds >= 60;
};

const NewPlayer = ({ videoTitle, videoUrl, lessonId, disableProgress }) => {
  const [progress, setProgress] = React.useState(0);
  const [videoLength, setVideoLength] = React.useState();
  const { isAuthenticated } = useContext(AppContext);

  const [lastSave, setLastSave] = useState(null);

  const logActionOnVideo = (e, type) => {
    if (disableProgress) return;
    if (!isAuthenticated) return;

    if (type === "onProgress") {
      const video = e.trigger.target;
      const localVidLength = video.duration;
      setVideoLength(localVidLength);
    } else if (type === "onTimeUpdate" && videoLength) {
      const currentTime = e.detail.currentTime;
      const percentWatched = Math.round((currentTime / videoLength) * 100);
      const haveWatchedEnough = percentWatched > progress;
      if (haveWatchedEnough) {
        const hasItBeenLongEnoughSinceLastSave = getWasLastSaveLongerThanOneMinuteAgo(lastSave);
        if (hasItBeenLongEnoughSinceLastSave) {
          setProgress(percentWatched);
        }
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated && !disableProgress) {
      axios
        .post(saveLessonProgressEndpoint, {
          lesson_id: lessonId,
          progress: progress,
        })
        .then(() => {
          setLastSave(new Date());
          console.log("Server progress updated to " + progress);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <Suspense fallback={<div>Loading Video...</div>}>
      <VidstackPlayer videoTitle={videoTitle} videoUrl={videoUrl} logActionOnVideo={logActionOnVideo} autoPlay={true} />
    </Suspense>
  );
};

export default NewPlayer;
