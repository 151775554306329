import { useState, useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack, Link, IconButton, InputAdornment } from "@mui/material";

import { AppContext } from "src/context/AppContext";
import Iconify from "src/components/icon/Iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { loginUrl, userDetailsEndpoint } from "src/urls";
import { errorToast } from "src/components_with_stories/toast";
import useIsLightMode from "src/hooks/useIsLightMode";

export default function AuthLoginForm() {
  const isLightMode = useIsLightMode();

  const { updateUserDetails } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const search = window.location.href.split("?")[1];
  const queryParameters = new URLSearchParams(search);
  const nextPage = queryParameters.get("next");

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Email is required.").email("Invalid email address."),
    password: Yup.string().required("Password is required.").min(8, "Your password must be at least 8 characters."),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const authenticateUsingStoredSessionCookie = () => {
    axios
      .get(userDetailsEndpoint)
      .then((response) => {
        const id = response.data.id;
        const email = response.data.email;
        const name = response.data.name;
        const phone = response.data.phone;
        const isSuperuser = response.data.is_superuser;
        updateUserDetails(id, email, name, phone, isSuperuser, false);
      })
      .catch((error) => {
        errorToast("Failed to authenticate. Please contact us for help.", isLightMode);
        window.scrollTo({ top: 0, behavior: "smooth" });
        console.log(error);
      });
  };

  const onSubmit = async ({ email, password }) => {
    axios
      .post(loginUrl, {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.key !== undefined) {
          axios.defaults.headers.common["X-CSRFToken"] = Cookies.get("csrftoken");
          reset();
          if (nextPage) {
            navigate(`/${nextPage}`);
          } else {
            navigate("/");
          }

          authenticateUsingStoredSessionCookie();
        }
      })
      .catch((response) => {
        const error = response.response.data;
        const nonFieldErrors = error.non_field_errors;
        let alertSent = false;
        if (nonFieldErrors) {
          if (nonFieldErrors[0] === "Unable to log in with provided credentials.") {
            errorToast("Incorrect email address or password.", isLightMode);
            window.scrollTo({ top: 0, behavior: "smooth" });
            alertSent = true;
          }
        }
        if (!alertSent) {
          errorToast("Failed to authenticate. Please contact us for help.", isLightMode);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        console.log(error);
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField hasFocus={true} name="email" label="Email address" tabIndex={1} />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          tabIndex={2}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end" inputProps={{ tabIndex: 4 }}>
                  <Iconify icon={showPassword ? "carbon:view" : "carbon:view-off"} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link component={RouterLink} to={"/forgot-password/"} variant="body2" underline="always" color="text.secondary">
          Forgot password?
        </Link>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          tabIndex={3}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
